<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
const type = [
  { label: '场景卡', value: '1' },
  { label: '垂直卡', value: '2' },
];
export default {
  data() {
    return {
      search: [
        { name: '名称', type: 'text', fieldName: 'keyword', value: '' },
        {
          fieldName: 'type',
          type: 'select',
          name: '类型',
          required: false,
          value: '',
          options: type,
        },
        {
          name: '粒子显示',
          type: 'select',
          fieldName: 'particle_show',
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
      ],
      config: {
        url: '/ticket-line/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'attachment_id', el.attachment_id ? [el.attachment] : []);
            this.$set(el, 'large_id', el.large_id ? [el.large] : []);
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        {
          name: '粒子显示',
          func: (ops) => (ops.particle_show ? '是' : '否'),
        },
        {
          name: '类型',
          func: (ops) => {
            if (ops.type === 1) return '场景卡';
            if (ops.type === 2) return '垂直卡';
            return '-';
          },
        },
        { name: '排序', prop: 'sort' },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
        {
          fieldName: 'particle_show',
          type: 'select',
          name: '粒子显示',
          required: true,
          value: '',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          fieldName: 'type',
          type: 'select',
          name: '类型',
          required: false,
          value: '',
          options: type,
        },
        { fieldName: 'sort', type: 'number', name: '排序', value: '', required: false },
        {
          fieldName: 'attachment_id',
          type: 'upload',
          name: '附件(节日350*420 垂直300*400)',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        {
          fieldName: 'large_id',
          type: 'upload',
          name: '大图(1920*890)',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/ticket-line/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/ticket-line/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
